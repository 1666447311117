<script>
import Layout from "@/router/layouts/franchise";
import { api } from "@/state/services";

export default {
  locales: {
    pt: {
    },
    es: {
    }
  },
  components: {
    Layout
  },
  data() {
    return {
      reports: []
    };
  },
  methods: {
    getReports() {
      api
        .get('franchise/reports')
        .then((response) => {
          if (response.data.status == 'success') {
            this.reports = response.data.list
          }
        })
    }
  },
  mounted() {
    this.getReports()
  }
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">{{ t('Reports') }}</h4>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
